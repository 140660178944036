import cdl from '../../Assets/MSImages/vecteezy_a-white-truck-on-highway-with-galaxy-background-insane_26423609_680.jpg';
import mechanic from '../../Assets/MSImages/vecteezy_car-mechanic-is-conducting-an-inspection-on-vehicle_1226524.jpg';
import it from '../../Assets/MSImages/vecteezy_asian-maintenance-engineer-works-on-the-roof-of-factory_19829043_212.jpg';
import industry from '../../Assets/MSImages/vecteezy_team-industry-engineer-are-working-and-discussing-at-front_28529378_314 (1).jpeg'

const SwiperData = [
    {
        id:1,
        title: 'Automotive Technician  ',
        img: mechanic,
        description: " Like working on cars? No need to sign up for long and expensive training through one of those aggressive companies. Dealerships will actually PAY YOU to learn how to work on cars.  Most mechanics will start off in the lube bay doing routine maintenance while they start learning the other systems. During this time, the dealership will be providing free ASE training on their systems. ",
        salary: "While entry-level mechanics generally feature fairly average pay, this pay goes up with experience and greater ASE certifications. Experienced mechanics are extremely well compensated. Additionally, many dealerships pay be “Flag Hours” rather than straight hours. This means that if a job is scheduled for 3 hours and you completed it sooner, you are paid for the entire 3 hours. This can allow an experienced and efficient mechanic to earn significantly higher wages.",
        schedule: "Automotive mechanics will generally be on a set weekly schedule depending on the needs of the dealership."
    },
    {
        id:2,
        title: 'Field Technician ',
        img: it,
        description: "Don’t want to be stuck inside at a desk? Want to constantly be doing something different? Consider working as a Field Service Technician.   Field Service Technicians work in a variety of different industries. Many Field Service Technician positions require extremely high travel (around 75%) so they may not be the best option if you are seeking more home time.  Most (depending on industry) Field Service Technicians’ work will be outside. Often this means being exposed to the elements in a variety of climates. You will most often be required to wear PPE (Personal Protective Equipment) which could mean fire-retardant coveralls, hard hats, gloves, fall protection, and more. A Field Service Technician will also likely need to be able to lift fairly heavy loads for short periods of time.",
        salary: "Field Service Technician positions are usually hourly positions. They will often feature a large amount of overtime built into the role. Because of this, often hourly salaries in the low to mid 20’s can lead to six-figure compensation. Additionally, Field Service Technicians who travel may earn per-diem, so that most or all of your daily expenses are covered while you travel. An experienced Field Service Technician, depending on industry, can earn greater than $40 per hour. ",
        schedule: "Field Service Technicians can work a variety of schedules. Depending on industry and location, they may work on a Rotating schedule, where they work for x number of days or weeks on, and then have x number of days/weeks off. This can allow for much greater flexibility during the off-time, instead of being limited to just Saturday and Sunday each week."
    },
    {
        id:3,
        title: 'Oil & Gas ',
        img: industry,
        description: "Like working outdoors? Don’t mind working hard? Like being part of a team with a mission and purpose? The Oil & Gas industry might be for you.  Workers within the Oil & Gas industry often work extremely long hours in difficult conditions  sometimes over 100 hours per week. Overtime is a huge part of compensation in this industry, and experienced workers can often earn extremely high salaries. Actual job descriptions can vary greatly between different product lines, but they all cater to the same kind of worker. Disciplined and determined employees can build an industry-wide reputation and will always be in demand.  Most new employees in this industry learn on the job. There are always experienced workers willing to train those who will listen, and the promotion opportunities are endless." ,
        salary: "Most workers within the Oil & Gas industry are hourly employees. Because of the large amounts of overtime required, salaries that seem low compared to some other jobs can result in extremely large paychecks. Additionally, some of these positions are rotational, so housing and per-diem is often provided.",
        schedule: "Oil & Gas workers generally work a set schedule of x days/weeks on, x days/weeks off depending on the employer and the job title. This can allow for much greater flexibility during off-time, instead of being limited to just Saturday and Sunday each week."
    },
    {
        id:4,
        title: 'CDL Drivers ',
        img: cdl,
        description: "Want to drive, but don’t want to get stuck over the road? Many industrial and oil & gas jobs requires a Commercial Drivers License. Some workers are required to deliver parts and equipment to production sites. Others must drive equipment out to a remote location, and then operate the equipment. These positions are often hourly, and most of the time the job doesn’t end once you reach your destination. These positions can often require long hours and hard work, but the salaries are often much higher than other driving positions. ",
        salary: "CDL Drivers within both industrial and the Oil & Gas industries are often hourly employees. The high pay offered in these positions is often due to extreme amounts of over-time.  ",
        schedule: "CDL Drivers in Industrial and Oil & Gas positions will often work on a Rotation schedule, where they work for x number of days or weeks On, and then have X number of days or weeks Off. This can allow for much greater flexibility during the off-time, instead of just being limited to Saturday and Sunday each week. Additionally, this can yield more predictability for CDL holders than driving Over the Road might offer. "
    },
]



export { SwiperData }