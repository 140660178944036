import React, { useEffect, useRef, useState } from "react"

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet"

import "leaflet/dist/leaflet.css"

import { Icon, divIcon, point } from "leaflet"

import "../Assets/scss/LeafLet.scss"

import Button from 'react-bootstrap/Button';

import Offcanvas from 'react-bootstrap/Offcanvas';

import Carson from '../Assets/img/forts/CRSN-FortCarsonGateSign.jpg'

import Campbell from '../Assets/img/forts/1time-fort-cambell-bg.jpeg.jpg'

import Johnson from '../Assets/img/forts/1time-fort-johnson-bg.jpeg.jpg'

import Riley from '../Assets/img/forts/FORT RILEY.jpg'

import Jacksonvile from '../Assets/img/forts/Jacksonvile NAVAL.jpg'

import Twentynine from '../Assets/img/forts/MCAGC Twentynine Palms,.jpeg'

import Mcas from '../Assets/img/forts/MCAS yuma.jpeg'

import Nellis from '../Assets/img/forts/Nellis.jpeg'

import Drum from '../Assets/img/forts/fort Drum.jpeg'

import Sill from '../Assets/img/forts/fort Sill images.jpeg'

import Cavazos from '../Assets/img/forts/fort cavazos.image.jpg'

import Liberty from '../Assets/img/forts/fort liberty.jpg'

import Moore from '../Assets/img/forts/fort moore.jpg'

import Kaneohe from '../Assets/img/forts/kaneohe MCAS.jpeg'

import Bliss from '../Assets/img/forts/fort Bliss.jpg'

 

 

 

 

 

 


const customIcon = new Icon({

  iconUrl: require("../Assets/Icons/download.png"),

  iconSize: [38, 38], 

})

 

const locations = [

  {

    id: 1,

    longitude: -98.4153,

    latitude: 34.6095804,

    name: "Fort Sill, Oklahoma",

    img: Sill,

      fortName: "Fort Sill",

      description: "Fort Sill is a United States Army post north of Lawton, Oklahoma, about 85 miles (137 km) southwest of Oklahoma City. It covers almost 94,000 acres (38,000 ha).[2]",

      militaryAndCivilianPersonnel: 20000,

      militaryFamilyMembers: 33000,

      Garrison: [ "United States Army Field Artillery School","United States Army Air Defense Artillery School",

        "75th Field Artillery Brigade","31st Air Defense Artillery Brigade"]

   

  },

  {

    id: 2,

    longitude: -106.4214983,

    latitude: 31.80116346,

    name: "Fort Bliss, Texas",

    img: Bliss,

    fortName: "Fort Bliss",

    description:"Fort Bliss is a United States Army post in New Mexico and Texas, with its headquarters in El Paso, Texas. Named in honor of LTC William Bliss (1815–1853), a mathematics professor who was the son-in-law of President Zachary Taylor, Ft. Bliss has an area of about 1,700 square miles (4,400 km2); it is the largest installation in FORSCOM (United States Army Forces Command) and second-largest in the Army overall (the largest being the adjacent White Sands Missile Range).",

    militaryAndCivilianPersonnel: 44169,

    militaryFamilyMembers: 35057 ,

    garrison:   ["1st Armored Division","15th Sustainment Brigade","402nd Field Artillery Brigade" ,"5th Armored Brigade"

    ,"32nd Army Air and Missile Defense Command","11th Air Defense Artillery Brigade","86th Expeditionary Signal Battalion"

    ,"Joint Task Force North", "German Air Force Command","German Air Force Air Defense Center"]

  },

  {

    id: 3,

    longitude: -79.00603,

    latitude: 35.139,

    name: "Fort Liberty, North Carolina",

    img: Liberty,

    fortName: "Fort Liberty",

    description:"Fort Liberty, formerly Fort Bragg, is a military installation of the United States Army in North Carolina, and is one of the largest military installations in the world by population, with over 52,000 military personnel.[2] The military reservation is located within Cumberland and Hoke counties,[3] and borders the towns of Fayetteville, Spring Lake, and Southern Pines.Fort Liberty covers over 251 square miles (650 km2).",

    militaryAndCivilianPersonnel: 54413,

    militaryFamilyMembers: 98273 ,

    garrison:[ "XVIII Airborne Corps", " U.S. Army 1st Special Forces Command (Airborne)", "75th Ranger Regiment"]

  },

  {

    id: 4,

    longitude: -97.741314,

    latitude: 31.195001,

    name: "Fort Cavazos, Texas",

    img: Cavazos,

    fortName: "Fort Cavazos",

    description:"Fort Cavazos is a United States Army post located near Killeen, Texas. The post is named after Gen. Richard E. Cavazos, a native Texan and the US Army’s first Hispanic four-star general. Formerly named Fort Hood for Confederate General John Bell Hood, the post is located halfway between Austin and Waco, about 60 mi (97 km) from each, within the U.S. state of Texas. Its origin was the need for wide-open space to test and train with World War II tank destroyers.",

    militaryAndCivilianPersonnel: 50676,

    militaryFamilyMembers: 421286  ,

    garrison:["III Armored Corps" ,"First Army Division West"]

  },

  {

    id: 5,

    longitude: -75.748778,

    latitude: 44.058048,

    name: "Fort Drum, New York",

    img: Drum,

    fortName: "Fort Drum",

    description:"Fort Drum is a U.S. Army military reservation and a census-designated place (CDP) in Jefferson County, near the western border of northern New York, United States. Fort Drum consists of 107,265 acres (434.09 km2). In this region, winter temperatures can reach as low as −30 °F (−34 °C).[4] Its mission includes command of active component units assigned to the installation, providing administrative and logistical support to tenant units, support to active and reserve units from all services in training at Fort Drum, and planning and support for the mobilization and training of almost 80,000 troops annually.",

    militaryAndCivilianPersonnel: 18700,

    militaryFamilyMembers: 22000 ,

    garrison:[  "10th Mountain Division"]

  },

  {

    id: 6,

    longitude: -114.650398,

    latitude: 32.698437,

    name: "MCAS Yuma, Arizona",

    img: Mcas,

    fortName: "MCAS Yuma",

    description:"Marine Corps Air Station Yuma or MCAS Yuma (ICAO: KNYL, FAA LID: NYL) is a United States Marine Corps air station. The station is 2 miles (1.7 nmi; 3.2 km) from the city of Yuma, Arizona. A joint use civilian-military airport, MCAS Yuma shares airfield facilities with Yuma International Airport and occupies approximately 3,000 acres (1,200 ha), most of which is flat desert.",

    militaryAndCivilianPersonnel: 7500,

    militaryFamilyMembers: 3400 ,

    garrison:["Marine Aircraft Group 13"]

   

  },

  {

    id: 7,

    longitude: -93.263779,

    latitude: 31.143259,

    name: "Fort Johnson, Louisiana",

    img: Johnson,

    fortName: "Fort Johnson",

    description:"Fort Johnson, formerly Fort Polk, is a United States Army installation located in Vernon Parish, Louisiana, about 10 miles (15 km) east of Leesville and 30 miles (50 km) north of DeRidder in Beauregard Parish. The land that is now Fort Johnson is part of a region of cultural resources, including archaeological sites, historic houses and structures, and other sites of historical value. The U.S. Army has spent considerable time, effort, and money to find and inventory thousands of archaeological sites on Fort Johnson and the property owned by the U.S. Forest Service where the Army trains.",

    militaryAndCivilianPersonnel: 14132,

    militaryFamilyMembers: 33297 ,

    garrison:["1st Battalion, 509th Infantry Regiment", "3rd Brigade Combat Team", "10th Mountain Division", "115th Combat Support Hospital", "Bayne-Jones Army Community Hospital"] ,

 

 

  },

  {

    id: 8,

    longitude: -116.054413,

    latitude: 34.135639,

    name: "MCAGCC, California",

    img: Twentynine,

    fortName: "Marine Corps Air Ground Combat Center (MCAGCC)",

    description:"The Marine Corps Air Ground Combat Center (MCAGCC), also known as 29 Palms, is the largest United States Marine Corps base. The base covers a total area of 596,288 acres (931.7 sq mi). It was a census-designated place (CDP) officially known as Twentynine Palms Base located adjacent to the city of Twentynine Palms in southern San Bernardino County, California. ",

    militaryAndCivilianPersonnel: 33500,

    militaryFamilyMembers: 24000 ,

    garrison:["7th Marine Regiment", "3rd Battalion 11th Marines","3rd LAR Battalion","3d Assault Amphibian Battalion"] ,

 

 

  },

  {

    id: 9,

    longitude: -96.83723,

    latitude: 39.02829,

    name: "Fort Riley, Kansas",

    img: Riley,

    fortName: "Fort Riley",

    description:"Fort Riley is a United States Army installation located in North Central Kansas, on the Kansas River, also known as the Kaw, between Junction City and Manhattan. The Fort Riley Military Reservation covers 101,733 acres (41,170 ha) in Geary and Riley counties. Fort Riley is named in honor of Major General Bennet C. Riley, who led the first military escort along the Santa Fe Trail. The fort was established in 1853 as a military post to protect the movement of people and trade over the Oregon, California, and Santa Fe trails.",

    militaryAndCivilianPersonnel: 14814,

    militaryFamilyMembers: 35057 ,

    garrison:["1st Infantry Division"]

  },

  {

    id: 10,

    longitude: -104.821365,

    latitude: 38.833881,

    name: "Fort Carson, Colorado",

    img: Carson,

    fortName: "Fort Carson ",

    description:"Fort Carson is a United States Army post located directly south of Colorado Springs in El Paso, Pueblo, Fremont, and Huerfano counties, Colorado, United States. The developed portion of Fort Carson is located near the City of Colorado Springs in El Paso County. Camp Carson was established in 1942, following Japan's attack on Pearl Harbor. ",

    militaryAndCivilianPersonnel: 34200,

    militaryFamilyMembers: 39222 ,

    garrison:["4th Infantry Division", "10th Special Forces Group", " 4th Security Force Assistance Brigade"] ,

 

 

  },

  {

    id: 11,

    longitude: -87.53289,

    latitude: 36.58556,

    name: "Fort Campbell, Tennessee",

    img: Campbell,

    fortName: "Fort Campbell",

    description:"Fort Campbell is a United States Army installation located astride the Kentucky–Tennessee border between Hopkinsville, Kentucky and Clarksville, Tennessee (post address is located in Kentucky). The fort is named in honor of Union Army Brigadier General William Bowen Campbell, the last Whig Governor of Tennessee.",

    militaryAndCivilianPersonnel: 33391,

    militaryFamilyMembers: 82933 ,

    garrison:["101st Airborne Division" ,"160th Special Operations" ,"Aviation Regiment" ,"5th Special Forces Group", "52nd Ordnance Group (EOD)"] ,

 

  },

 

  {

    id: 12,

    longitude: -157.7521,

    latitude: 21.4446,

    name: "MCB Kaneohe Hawaii",

    img: Kaneohe,

    fortName: "Marine Corps Base Hawaii",

    description:"Marine Corps Base Hawaii (MCBH), formerly Marine Corps Air Station Kaneohe Bay and originally Naval Air Station Kaneohe Bay, is a U.S. Marine Corps facility and air station located on the Mokapu Peninsula of windward O'ahu in the City & County of Honolulu. Marine Corps Base Hawaii is home to Marines, Sailors, their family members, and civilian employees. The United States Marine Corps operates a 7,800-foot (2,400 m) runway at the base. The base lies between the two largest windward O'ahu communities of Kailua and Kāne'ohe, and the main gate is reached at the eastern end of Interstate H-3. The main access to the base is by either H-3 or Mokapu Road. MCB Hawaii is located on the windward side of Oahu, approximately 12 miles (19 km) northeast of Honolulu. ",

    militaryAndCivilianPersonnel: 7000,

    militaryFamilyMembers: 18000 ,

    garrison:["3rd Marine Regiment", "Marine Aircraft Group 24" , "Combat Logistics Battalion 3", "3rd Radio Battalion"] ,

 

  },

  {

    id: 13,

    longitude: -81.655647,

    latitude: 30.332184,

    name: "Naval Air Station Jacksonville, Florida",

    img: Jacksonvile,

    fortName: "Naval Air Station Jacksonville (NAS Jacksonville)",

    description:"Naval Air Station Jacksonville (NAS Jacksonville) (IATA: NIP, ICAO: KNIP, FAA LID: NIP) is a large naval air station located approximately eight miles (13 km) south of the central business district of Jacksonville, Florida, United States.NAS Jacksonville is located in Duval County, Florida, within the city limits of Jacksonville. The base sits on a piece of land between the St. Johns River and Ortega River historically called Black Point. ",

    militaryAndCivilianPersonnel: 12066,

    militaryFamilyMembers: 34000 ,

    garrison:["Patrol and Reconnaissance Wing Eleven"] ,

 

  },

  {

    id: 14,

    longitude: -81.088371,

    latitude: 32.076176,

    name: "Fort Moore, Georgia",

    img: Moore,

    fortName: "Fort Moore",

    description:"Fort Moore (formerly Fort Benning) is a United States Army post near Columbus, Georgia, adjacent to the Alabama–Georgia border. Fort Moore supports more than 120,000 active-duty military, family members, reserve component soldiers, retirees and civilian employees on a daily basis. As a power projection platform, the post can deploy combat-ready forces by air, rail, and highway for their designated mission. It was originally named for Henry L. Benning, a brigadier general in the Confederate States Army during the Civil War.[1][2] Fort Benning was one of the ten U.S. Army installations named for former Confederate generals that were renamed on 11 May 2023,[3][4][5][6] following a recommendation from the congressionally mandated Naming Commission that Fort Benning be renamed Fort Moore after Lieutenant General Hal Moore and his wife Julia Compton Moore, both of whom are buried on post.",

    militaryAndCivilianPersonnel: 36514,

    militaryFamilyMembers: 26900 ,

    garrison:["United States Army Armor School", "United States Army Infantry School", "the Western Hemisphere Institute for Security Cooperation (formerly known as the School of the Americas)", "the 75th Ranger Regiment", "the 1st Security Force Assistance Brigade"] ,

 

  },

  {

    id: 15,

    longitude: -115.0572,

    latitude: 36.2461,

    name: "Nellis AFB, Nevada",

    img: Nellis,

    fortName: "Nellis Air Force Base",

    description:"Nellis Air Force Base is a United States Air Force installation in southern Nevada. Nellis hosts air combat exercises such as Exercise Red Flag and close air support exercises such as Green Flag-West flown in Military Operations Area (MOA) airspace, associated with the nearby Nevada Test and Training Range (NTTR). The base also has the Combined Air and Space Operations Center-Nellis.",

    militaryAndCivilianPersonnel: 14914,

    militaryFamilyMembers: 22728 ,

    garrison:["99th Air Base Wing"] ,

 

 

  },

]

 

const LeafLet = (props) => {

  const [location, setLocation] = useState([38.573936, -110.60376])

  const [zoom, setZoom] = useState(4)

  const [show, setShow] = useState(false);

  const [selectedLocation, setSelectedLocation]= useState({    id: -1,

    longitude: 0,

    latitude: 0,

    name: "",

    img: Nellis,

    fortName: "",

    description:"",

    militaryAndCivilianPersonnel: 0,

    militaryFamilyMembers: 0 ,

    garrison:[] ,

})

  const mapRef = useRef(null)

 

  const handleClose = () => {

    setShow(false)

    setLocation([38.573936, -100.60376])

    setZoom(3)

  };

 

  const handleShow = (newLocation, id) => {

console.log(id);

 

    const location = locations.find(item => item.id === id)

    console.log(location,"before");

    setSelectedLocation(location)

    setLocation(newLocation)

    setZoom(10)

    setShow(true)
  }

 
  useEffect(() => {

    if (mapRef.current) {

      mapRef.current.setView(location, zoom)

    }

  }, [location, zoom])

 

  const zoomToMarker = (lat, lag,id) => {

    handleShow([lat,lag],id)

   

  };

 

  return (

    <>
  <h6 style={{ fontSize: '30px' }} className="font-serif text-dark text-center my-40 mb-40px font-medium mb-[25px] lg:mb-[40px]">Transition Advisor Locations</h6>
      <Offcanvas className='my-40' show={show} onHide={handleClose}  >

        <Offcanvas.Header style={{display:"flex", justifyContent:"flex-end"}} closeButton>
          

<button onClick={handleClose}>x</button>

        </Offcanvas.Header>

        <Offcanvas.Body>

        <div className="offCanvas">
  <h3 className="location-title">{selectedLocation.fortName}</h3>
  <img className="location-image" src={selectedLocation.img} alt={selectedLocation.name} />
  <p className="location-description">{selectedLocation.description}</p>
  <div className="location-details">
    <div className="detail">
      <span className="detail-label">Military Personnel & Contractors:</span>
      <span className="detail-value">{selectedLocation.militaryAndCivilianPersonnel}</span>
    </div>
    <div className="detail">
      <span className="detail-label">Military Family Members:</span>
      <span className="detail-value">{selectedLocation.militaryFamilyMembers}</span>
    </div>
  </div>
  <div className="location-garrison">
    <h5>Garrisoned Units</h5>
    <ul>
      {selectedLocation.garrison.map((item, index) => (
        <li key={index} className="garrison-item">
          {item}
        </li>
      ))}
    </ul>
  </div>
</div>


        </Offcanvas.Body>

      </Offcanvas>

    <div className="mapContainer"  >

      <MapContainer

        ref={mapRef}

        center={location}

        zoom={zoom}

        scrollWheelZoom={true}

        minZoom={3}

        className="leafLetMap"
        style={{height:'40rem'}}

      >

        <TileLayer

          url="https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=yL7zHZfqLzGCu17EKzkL"

          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a></a>'

        />

        {locations.map((marker) => (

          <Marker

            key={marker.id + Math.random()}

            position={[marker.latitude, marker.longitude]}

            icon={customIcon}

            eventHandlers={{ dblclick: () => zoomToMarker(marker.latitude, marker.longitude,marker.id) }}

            title={marker.name}

          >
            <Popup>
           <div className="popupContent" >
            <img src={marker.img} alt="" />
            <span >{marker.name}</span>
            <Button className="buttonMap" variant="secondary" onClick={()=>handleShow([marker.latitude, marker.longitude], marker.id) }>
Details
      </Button>
           </div>
              </Popup>
          </Marker>
        ))}
      </MapContainer>

    </div>

    </>

  )

}

 

export default LeafLet