import Execs from '..//..//Assets/img/Execs.jpeg';

const TabData01 = [
    {
        subtitle: 'Job Seekers',
        title: 'Hiring Veterans',
        img: Execs,
        content: "Over 50% of Veterans leave their first post-service job within the first year. Make your first step out of the military the right one. We have been helping Veterans transition into great post-military careers for over 10 years. We focus on jobs and companies most Veterans won’t find on their own. Best of all? These jobs feature above-average wages and the employers provide the training.  And, finally, most of our positions can be done either post-service or as a DoD SkillBridge Internship. Contact us to learn more.",        buttonTitle: "Contact Us",        

        buttonLink: "/"

    }

]
const TabData02 = [
    {
        subtitle:'Employers',

        title:'Hiring Veterans',

        img: Execs,

        content: 'Veterans have a proven track record of producing excellent results in the most challenging environments, where each decision can mean the difference between mission success and failure. They bring marketable, relevant skills to any organization. Large companies have robust Veteran hiring programs. Military Solutions brings this important capability to small and medium-sized businesses. With over a decade of experience with military hiring, we can source and screen exceptional candidates for your company – giving you a competitive advantage while also protecting your bottom line. In addition to sourcing and screening some of the best candidates out there, we are able to increase the effectiveness of your program by leveraging programs like DoD SkillBridge and Department of Labor Registered Apprenticeships. Contact us today to learn more!',        buttonTitle: "Contact Us",        

        buttonLink: "/"

    }

]
 export { TabData01, TabData02 }