import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div>
      <>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="apple-touch-icon"
          href="http://192.168.2.72/images/64x64.jpg"
        />
        <link rel="shortcut icon" href="http://192.168.2.72/images/64x64.jpg" />
        <link
          rel="shortcut icon"
          href="http://192.168.2.72/images/favicon.ico"
        />
        <link
          href="./Privacy Policy_files/css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="./Privacy Policy_files/font-awesome.min.css"
          rel="stylesheet"
          type="text/css"
        />
        <base href="." />
        <meta
          name="keywords"
          content="military, transition, separating, skillbridge, careers"
        />
        <meta
          name="description"
          content="Transitioning military members meeting employers for launching life long careers."
        />
        <meta
          name="generator"
          content="Joomla! - Open Source Content Management"
        />
        <title>Privacy Policy</title>
        {/*<link href="https://mymapplan.com//privacy-policy" rel="canonical" />
        <link
          href="http://192.168.2.72/templates/mymapplan/favicon.ico"
          rel="shortcut icon"
          type="image/vnd.microsoft.icon"
        />
        <link
          href="./Privacy Policy_files/content.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="./Privacy Policy_files/bootstrap.min.css"
          rel="stylesheet"
          type="text/css"
          media="screen,projection"
        />
        <link
          href="./Privacy Policy_files/style.css"
          rel="stylesheet"
          type="text/css"
          media="screen,projection"
        />
        <link
          href="./Privacy Policy_files/notification.css"
          rel="stylesheet"
          type="text/css"
        />*/}
        <div className="container">
          <div>
            <div className="col-lg-12 col-xs-12">
              <div
                className="item-page"
                itemScope=""
                itemType="https://schema.org/Article"
              >
                <meta itemProp="inLanguage" content="en-GB" />
                <div className="page-header">
                  <h2 itemProp="headline">Military Solution Privacy Policy</h2>
                </div>
                <div itemProp="articleBody">
                  <div id="ppBody">
                    <div className="ppConsistencies"></div>
                    <div className="ppConsistencies"></div>
                    <div style={{ clear: "both", height: 10 }}>&nbsp;</div>
                    <hr />
                    <div
                      className="innerText"
                      style={{ justifyContent: "left" }}
                    >
                      This privacy policy has been compiled to better serve
                      those who are concerned with how their 'Personally
                      identifiable information' (PII) is being used online. PII,
                      as used in US privacy law and information security, is
                      information that can be used on its own or with other
                      information to identify, contact, or locate a single
                      person, or to identify an individual in context. Please
                      read our privacy policy carefully to get a clear
                      understanding of how we collect, use, protect or otherwise
                      handle your Personally Identifiable Information in
                      accordance with our website.
                    </div>
                    <span />
                    <br />
                    <div id="infoCo">
                      <div className="grayText">
                        <strong>
                          What personal information do we collect from the
                          people that visit our blog, website or app?
                        </strong>
                      </div>
                    </div>
                    <br />
                    <div className="innerText">
                      When ordering or registering on our site, as appropriate,
                      you may be asked to enter your name, email address, phone
                      number, credit card information, photo or other details to
                      help you with your experience.
                    </div>
                    <br />
                    <div className="grayText">
                      <strong>When do we collect information?</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      We collect information from you when you register on our
                      site, place an order, fill out a form or enter information
                      on our site.
                    </div>
                    <br />
                    <span id="infoUs" />
                    <br />
                    <div className="grayText">
                      <strong>How do we use your information? </strong>
                    </div>
                    <br />
                    <div className="innerText">
                      We may use the information we collect from you when you
                      register, make a purchase, sign up for our newsletter,
                      respond to a survey or marketing communication, surf the
                      website, or use certain other site features in the
                      following ways:
                      <br />
                      <br />
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To
                      personalize user's experience and to allow us to deliver
                      the type of content and product offerings in which you are
                      most interested.
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To
                      improve our website in order to better serve you.
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To allow
                      us to better service you in responding to your customer
                      service requests.
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To
                      quickly process your transactions.
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To allow
                      Colleges, Employers and Other Potential Service Providers
                      to view and send emails, text messages or promotional
                      correspondence to you regarding their products, services
                      or organizations.
                    </div>
                    <span id="infoPro" />
                    <br />
                    <div className="grayText">
                      <strong>How do we protect visitor information?</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      Our website is scanned on a regular basis for security
                      holes and known vulnerabilities in order to make your
                      visit to our site as safe as possible.
                      <br />
                      <br />
                    </div>
                    <div className="innerText">
                      We use regular Malware Scanning.
                      <br />
                      <br />
                    </div>
                    <div className="innerText">
                      Your personal information is contained behind secured
                      networks and is only accessible by a limited number of
                      persons who have special access rights to such systems,
                      and are required to keep the information confidential. In
                      addition, all sensitive/credit information you supply is
                      encrypted via Secure Socket Layer (SSL) technology.
                    </div>
                    <br />
                    <div className="innerText">
                      We implement a variety of security measures when a user
                      places an order enters, submits, or accesses their
                      information to maintain the safety of your personal
                      information.
                    </div>
                    <br />
                    <div className="innerText">
                      All transactions are processed through a gateway provider
                      and are not stored or processed on our servers.
                    </div>
                    <span id="coUs" />
                    <br />
                    <div className="grayText">
                      <strong>Do we use 'cookies'?</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      Yes. Cookies are small files that a site or its service
                      provider transfers to your computer's hard drive through
                      your Web browser (if you allow) that enables the site's or
                      service provider's systems to recognize your browser and
                      capture and remember certain information. For instance, we
                      use cookies to help us remember and process the items in
                      your shopping cart. They are also used to help us
                      understand your preferences based on previous or current
                      site activity, which enables us to provide you with
                      improved services. We also use cookies to help us compile
                      aggregate data about site traffic and site interaction so
                      that we can offer better site experiences and tools in the
                      future.
                    </div>
                    <div className="innerText">
                      <br />
                      <strong>We use cookies to:</strong>
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong>{" "}
                      Understand and save user's preferences for future visits.
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Keep
                      track of advertisements.
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Compile
                      aggregate data about site traffic and site interactions in
                      order to offer better site experiences and tools in the
                      future. We may also use trusted third party services that
                      track this information on our behalf.
                    </div>
                    <div className="innerText">
                      <br />
                      You can choose to have your computer warn you each time a
                      cookie is being sent, or you can choose to turn off all
                      cookies. You do this through your browser (like Internet
                      Explorer) settings. Each browser is a little different, so
                      look at your browser's Help menu to learn the correct way
                      to modify your cookies.
                    </div>
                    <br />
                    <div className="innerText">
                      If you disable cookies off, some features will be disabled
                      It won't affect the users experience that make your site
                      experience more efficient and some of our services will
                      not function properly.
                    </div>
                    <br />
                    <div className="innerText">
                      However, you can still place orders .
                    </div>
                    <br />
                    <span id="trDi" />
                    <br />
                    <div className="grayText">
                      <strong>Third Party Disclosure</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      We do not sell, trade, or otherwise transfer to outside
                      parties your personally identifiable information unless we
                      provide you with advance notice. This does not include
                      website hosting partners and other parties who assist us
                      in operating our website, conducting our business, or
                      servicing you, so long as those parties agree to keep this
                      information confidential. We may also release your
                      information when we believe release is appropriate to
                      comply with the law, enforce our site policies, or protect
                      ours or others' rights, property, or safety. <br />
                      <br />
                      Given the business nature of MyMapPlan, we may share your
                      personally identifiable information with Colleges,
                      Employers or Other Service Providers with whom we have a
                      relationship. &nbsp;This also includes website hosting
                      partners and other parties who assist us in operating our
                      website, conducting our business, or servicing you, so
                      long as those parties agree to keep this information
                      confidential. We may also release your information when we
                      believe release is appropriate to comply with the law,
                      enforce our site policies, or protect ours or others'
                      rights, property, or safety.
                    </div>
                    <span id="trLi" />
                    <br />
                    <div className="grayText">
                      <strong>Third party links</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      Occasionally, at our discretion, we may include or offer
                      third party products or services on our website. These
                      third party sites have separate and independent privacy
                      policies. We therefore have no responsibility or liability
                      for the content and activities of these linked sites.
                      Nonetheless, we seek to protect the integrity of our site
                      and welcome any feedback about these sites.
                    </div>
                    <span id="gooAd" />
                    <br />
                    <div className="blueText">
                      <strong>Google</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      Google's advertising requirements can be summed up by
                      Google's Advertising Principles. They are put in place to
                      provide a positive experience for users.
                      https://support.google.com/adwordspolicy/answer/1316548?hl=en
                      <br />
                      <br />
                    </div>
                    <div className="innerText">
                      We use Google AdSense Advertising on our website.
                    </div>
                    <div className="innerText">
                      <br />
                      Google, as a third party vendor, uses cookies to serve ads
                      on our site. Google's use of the DART cookie enables it to
                      serve ads to our users based on their visit to our site
                      and other sites on the Internet. Users may opt out of the
                      use of the DART cookie by visiting the Google ad and
                      content network privacy policy.
                    </div>
                    <div className="innerText">
                      <br />
                      <strong>We have implemented the following:</strong>
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong>{" "}
                      Remarketing with Google AdSense
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong>{" "}
                      Demographics and Interests Reporting
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong>{" "}
                      DoubleClick Platform Integration
                    </div>
                    <br />
                    <div className="innerText">
                      We along with third-party vendors, such as Google use
                      first-party cookies (such as the Google Analytics cookies)
                      and third-party cookies (such as the DoubleClick cookie)
                      or other third-party identifiers together to compile data
                      regarding user interactions with ad impressions, and other
                      ad service functions as they relate to our website.
                    </div>
                    <div className="innerText">
                      <br />
                      <i>
                        Opting out:
                        <br />
                        Users can set preferences for how Google advertises to
                        you using the Google Ad Settings page. Alternatively,
                        you can opt out by visiting the Network Advertising
                        initiative opt out page or permanently using the Google
                        Analytics Opt Out Browser add on.
                      </i>
                    </div>
                    <span />
                    <br />
                    <div id="calOppa" className="blueText">
                      <strong>California Online Privacy Protection Act</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      CalOPPA is the first state law in the nation to require
                      commercial websites and online services to post a privacy
                      policy. The law's reach stretches well beyond California
                      to require a person or company in the United States (and
                      conceivably the world) that operates websites collecting
                      personally identifiable information from California
                      consumers to post a conspicuous privacy policy on its
                      website stating exactly the information being collected
                      and those individuals with whom it is being shared, and to
                      comply with this policy. - See more at:
                      http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                    </div>
                    <div className="innerText">
                      <br />
                      <strong>
                        According to CalOPPA we agree to the following:
                      </strong>
                    </div>
                    <div className="innerText">
                      Users can visit our site anonymously
                    </div>
                    <div className="innerText">
                      Once this privacy policy is created, we will add a link to
                      it on our home page, or as a minimum on the first
                      significant page after entering our website.
                    </div>
                    <div className="innerText">
                      Our Privacy Policy link includes the word 'Privacy', and
                      can be easily be found on the page specified above.
                    </div>
                    <div className="innerText">
                      <br />
                      Users will be notified of any privacy policy changes:
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> On our
                      Privacy Policy Page
                    </div>
                    <div className="innerText">
                      Users are able to change their personal information:
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> By
                      calling us
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> By
                      logging in to their account
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> By
                      chatting with us or sending us a ticket
                    </div>
                    <div className="innerText">
                      <br />
                      <strong>
                        How does our site handle do not track signals?
                      </strong>
                    </div>
                    <div className="innerText">
                      We honor do not track signals and do not track, plant
                      cookies, or use advertising when a Do Not Track (DNT)
                      browser mechanism is in place.
                    </div>
                    <div className="innerText">
                      <br />
                      <strong>
                        Does our site allow third party behavioral tracking?
                      </strong>
                    </div>
                    <div className="innerText">
                      It's also important to note that we allow third party
                      behavioral tracking
                    </div>
                    <span id="coppAct" />
                    <br />
                    <div className="blueText">
                      <strong>
                        COPPA (Children Online Privacy Protection Act)
                      </strong>
                    </div>
                    <br />
                    <div className="innerText">
                      When it comes to the collection of personal information
                      from children under 13, the Children's Online Privacy
                      Protection Act (COPPA) puts parents in control. The
                      Federal Trade Commission, the nation's consumer protection
                      agency, enforces the COPPA Rule, which spells out what
                      operators of websites and online services must do to
                      protect children's privacy and safety online.
                      <br />
                      <br />
                    </div>
                    <div className="innerText">
                      We do not specifically market to children under 13.
                    </div>
                    <span id="ftcFip" />
                    <br />
                    <div className="blueText">
                      <strong>Fair Information Practices</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      The Fair Information Practices Principles form the
                      backbone of privacy law in the United States and the
                      concepts they include have played a significant role in
                      the development of data protection laws around the globe.
                      Understanding the Fair Information Practice Principles and
                      how they should be implemented is critical to comply with
                      the various privacy laws that protect personal
                      information.
                      <br />
                      <br />
                    </div>
                    <div className="innerText">
                      <strong>
                        In order to be in line with Fair Information Practices
                        we will take the following responsive action, should a
                        data breach occur:
                      </strong>
                    </div>
                    <div className="innerText">
                      We will notify the users via email
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Within 7
                      business days
                    </div>
                    <div className="innerText">
                      We will notify the users via in site notification
                    </div>
                    <div className="innerText">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Within 1
                      business day
                    </div>
                    <div className="innerText">
                      <br />
                      We also agree to the individual redress principle, which
                      requires that individuals have a right to pursue legally
                      enforceable rights against data collectors and processors
                      who fail to adhere to the law. This principle requires not
                      only that individuals have enforceable rights against data
                      users, but also that individuals have recourse to courts
                      or a government agency to investigate and/or prosecute
                      non-compliance by data processors.
                    </div>
                    <span id="canSpam" />
                    <br />
                    <div className="blueText">
                      <strong>CAN SPAM Act</strong>
                    </div>
                    <br />
                    <div className="innerText">
                      The CAN-SPAM Act is a law that sets the rules for
                      commercial email, establishes requirements for commercial
                      messages, gives recipients the right to have emails
                      stopped from being sent to them, and spells out tough
                      penalties for violations.
                      <br />
                      <br />
                    </div>
                    <div className="innerText">
                      <strong>
                        We collect your email address in order to:
                      </strong>
                    </div>
                    <div className="innerText">
                      <br />
                      <strong>
                        To be in accordance with CANSPAM we agree to the
                        following:
                      </strong>
                    </div>
                    <div className="innerText">
                      <strong>
                        <br />
                        If at any time you would like to unsubscribe from
                        receiving future emails, you can email us at
                        <a href="mailto: m.hardy@erasolutions.us">
                          m.hardy@erasolutions.us
                        </a>
                        &nbsp;
                      </strong>
                      and we will promptly remove you from
                      <strong> ALL</strong> correspondence.
                    </div>
                  </div>
                  <p>
                    <br />
                    <span id="ourCon" />
                  </p>
                  <div className="blueText">
                    <strong>Contacting Us</strong>
                  </div>
                  <p>&nbsp;</p>
                  <div className="innerText">
                    If there are any questions regarding this privacy policy you
                    may contact us using the information below.
                    <br />
                    <br />
                  </div>
                  {/* <div className="innerText">militarysolutions.net</div>
                  <div className="innerText">3841 E Danforth Rd Suite 105</div>
                  <div className="innerText">Edmond</div>
                  <div className="innerText">Oklahoma</div>
                  <div className="innerText">73034</div>
                  <div className="innerText">USA</div>
                  <div className="innerText">&nbsp;</div> */}
                  <div className="innerText">
                    <a href="mailto:mhardy@militarysolution.net">
                      mhardy@militarysolution.net
                    </a>
                  </div>
                  <div className="innerText">ph: 936.391.8443</div>
                  <div className="innerText">
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Footer*/}
          <div className="row margin-top-15">
            <div className="footer container">
              <hr />
              <div className="col-lg-9 col-md-10 col-sm-7">
                <p className="copyright">
                  MilSol © 2023. All Rights Reserved.
                  <a
                    href="http://192.168.2.72/privacy-policy"
                    target="_blank"
                    rel="alternate noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="http://192.168.2.72/terms-and-conditions"
                    target="_blank"
                    rel="alternate noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </p>
                <p className="copyright">
                  "Myers-Briggs Type Indicator(r) and MBTI(r) are registered
                  trademarks of the The Myers &amp; Briggs Foundation. MyMapPlan
                  holds no rights in these trademarks."
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
