import React from 'react'

// Libraries
import { Col, Container, Row, } from 'react-bootstrap'
import { m } from 'framer-motion'

// Components
import { fadeIn } from '../../Functions/GlobalAnimations'
import Tab01 from './Tab01'


// Data
import { TabData01 } from './TabData'


const TabPage = () => {
  return (
    <>
      <m.section style={{marginTop:'0', marginBottom:'5rem'}} className="py-[160px] bg-white lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"  {...fadeIn}>
        <Container>
          <Row className="justify-center">
            <Col md={18} className="text-center mb-[6%]">
            </Col>
          </Row>
          <Tab01 data={TabData01} />
        </Container>
      </m.section>
    </>
  )
}

export default TabPage