import React, { memo, useState } from 'react';
import { Modal, Button} from 'react-bootstrap';



// Libraries

import { Col, Tab, Tabs } from 'react-bootstrap'

import { PropTypes } from "prop-types";

import { m } from "framer-motion";



// Components

import Buttons from './Buttons'

import { fadeIn } from "../../Functions/GlobalAnimations";



// Data

import { TabData01 } from './TabData'



const Tab01 = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <Tabs className="font-serif mb-[7.4rem] flex bg-transparent border-b-0 font-medium text-center justify-center md:mb-[60px] sm:mb-[20px]">

            {

                props.data.map((item, i) => {

                    return (

                        <Tab className="mx-auto flex flex-col min-h-screen max-w-screen-sm items-center justify-center " key={i} eventKey={i}>

                            <m.div className='items-center m-[0] w-full rounded-md bg-gradient-to-r from-blue-500 to-blue-200 p-1' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>

                                <m.div class="flex lg:flex-row md:flex-col h-full w-full items-center justify-center bg-white back ">

                                    <Col className="align-self-center m-[10px] ">

                                        {item.img && <img height="500" width="525" className="" src={item.img} alt="tab" />}

                                    </Col>

                                    <Col className="align-self-center text-left m-[10px]">

                                        {item.subtitle && <span className="text-gradient bg-gradient-to-r from-[#556fff] to-blue-400 mb-[15px] font-serif text-lg font-medium uppercase inline-block leading-[20px] mt-[1rem]">{item.subtitle}</span>}

                                        {item.title && <h5 className="text-darkgray block mb-[10px] font-serif font-medium">{item.title}</h5>}

                                        {item.content && <p className="mb-[25px] w-[100%] md:w-full xs:mb-[15px] text-left m-[10]">{item.content}</p>}
                                        {(item.buttonTitle) && <Buttons ariaLabel="tab button" className="btn-fill rounded-10px font-medium font-serif tracking-[1px] uppercase btn-fancy md:mb-[15px] xs:mb-0 xs:mt-[20px] m-[5px]" size="md" themeColor="#98afc7" color="#ffffff" onClick={handleShow} title={item.buttonTitle} />}
                                        <Modal size="lg" className='h-screen ' show={show} onHide={handleClose}>

                                            <Modal.Body className='xl:h-[600px]  lg:h-[550px]'>
                                                <iframe
                                                    src="https://forms.monday.com/forms/embed/105e76e65e192801b4e114f8be36bdd8?r=use1"
                                                    style={{ width: "100%", height: "100%" }}
                                                ></iframe>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Col>

                                </m.div>

                            </m.div>

                        </Tab>

                    )

                })

            }

        </Tabs>

    )

}



Tab01.defaultProps = {

    data: TabData01

}



Tab01.propTypes = {

    data: PropTypes.arrayOf(

        PropTypes.exact({

            tabTitle: PropTypes.string,

            subtitle: PropTypes.string,

            title: PropTypes.string,

            img: PropTypes.string,

            content: PropTypes.string,

            buttonLink: PropTypes.string,

            buttonTitle: PropTypes.string,

            tabicons: PropTypes.string

        })

    ),

}





export default memo(Tab01)