import React from "react";
import Header, { HeaderNav, Menu } from "./Header";
import { Col, Navbar } from "react-bootstrap";
import HeaderData from "./HeaderData";
import { Link } from "react-router-dom";
import mainLogo from "../../Assets/img/MilitarySolutions-Horizontal.png";

const CenterLogoNav = (props) => {
  return (
    <div>
      <Header
        className="header-with-topbar"
        topSpace={{ desktop: true }}
        type="reverse-scroll"
      >
        <HeaderNav
          bg="white"
          theme="light"
          containerClass="!px-0"
          className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0"
        >
          <Col
            lg={6}
            xs={"auto"}
            className="px-lg-0 position-absolute left-0 right-0 mx-lg-auto text-center md:!relative mr-auto"
          >
            <Link
              aria-label="header logo"
              className="inline-block relative z-10"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0 align-middle">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={mainLogo}
                  data-rjs="/assets/img/webp/logo-black@2x.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={mainLogo}
                  data-rjs="/assets/img/webp/logo-black@2x.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={mainLogo}
                  data-rjs="/assets/img/webp/logo-black@2x.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Col className="md:flex md:justify-end md:px-[15px]">
            <Navbar.Toggle className="order-last md:ml-[17px]">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
            <div className="hidden md:block">
              <Navbar.Collapse className="col-auto justify-center">
                <Menu {...props} />
              </Navbar.Collapse>
            </div>
            <div className="flex justify-between md:hidden col-lg-9 col-xl-8 mx-auto !pl-[25px] !pr-[12px] lg:!pl-[15px] lg:!pr-0">
              <Menu
                data={HeaderData.slice(0, Math.floor(HeaderData.length / 2))}
              />
              <Menu
                data={HeaderData.slice(
                  Math.floor(HeaderData.length / 2),
                  HeaderData.length
                )}
              />
            </div>
          </Col>
        </HeaderNav>
      </Header>
    </div>
  );
};

export default CenterLogoNav;
