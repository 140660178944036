import React from 'react'
import IconWithTextPage from "./IconWithText";
import CenterLogoNav from './Elements/CenterLogoNav'
import HeroImage from './Elements/HeroImage';
import TabPage2 from './Elements/TabEmployer';
import Footer from '../Components/Footer';

const Employer = () => {
  return (
    <div>
        <CenterLogoNav/>
        <HeroImage/>
        <IconWithTextPage/>
        <TabPage2 />
        <Footer/>
    </div>
  )
}

export default Employer