import { useState, useEffect } from 'react';
import { sliderData } from '../Elements/HeroImage-data';
import "../../Assets/scss/HeroImage.scss";
const sentences = [
  "Fueling Progress, Powering the Future.",
  "Driving Excellence, One Repair at a Time.",
  "Hard Hats, Strong Hearts.",
  "Delivering Success, On Time Every Time.",
  "On the Road to Excellence."
];

const HeroImage = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((oldIndex) => {
        return (oldIndex + 1) % sentences.length;
      });
    }, 8000); 
    return () => clearInterval(interval);
  }, []);
const subtag = sentences[index]
  return (
    <div className="slider">
      {sliderData.map((slide, index) => {
        return (
          <div className="vid-section">
            <div className='vid-container '>
              <video src={slide.vid} id="video" autoPlay loop muted />
              <div className='overlay'></div>
              <div className='tagline-cont'>
                <h2 className='tagline'>Seamless Veteran Talent Integration</h2>
                <h3 className='tagline2'>{subtag}</h3>
              </div>
            </div>
          </div>
        )
      })}
  
    </div>
  )
}

export default HeroImage