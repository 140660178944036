import styled from "styled-components";

//box around footer
export const Box = styled.div`
  //background: lightgrey;
  bottom: 0;
  width: 100%;
  // @media (max-width: 1000px) {
  //   padding: 70px 30px;
  // }
`;
//links/topics
export const Container = styled.div`
  display: flex;
  flex-wrap
  flex-direction: row;
  justify-content: center;
  max-width: 1000px;
  //margin: 0 auto;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justified-content: center;
`;

export const Row = styled.div`
  //display: flex;
  //grid-template-rows: repeat(auto-fill, minmax(185px, 1fr));
  //grid-gap: 1rem;

  // @media (max-width: 1198px) {
  //   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // }
`;

//Links
export const FooterLink = styled.a`
  color: black;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    color: #3975f6;
    transition: 200ms ease-in;
  }
`;

//topics
export const Heading = styled.p`
  font-size: 24px;
  color: black;
  margin-bottom: 40px;
  font-weight: bold;
`;
