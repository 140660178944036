import { Pagination } from 'swiper';
import EffectPanorama from './effect-panorama.esm.jsx';
import '../../Assets/scss/effect-panorama.scss';
import '../../Assets/scss/SwiperCarousel.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { width } from 'dom7';




const SwiperCarousel = ({ data }) => {
    const [show, setShow] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [currentText, setCurrentText] = useState('');
    const [description, setDescription] = useState('');
    const [salary, setSalary] = useState('');
    const [schedule, setSchedule] = useState('');



    const handleClose = () => setShow(false);
    const handleShow = (imgSrc, text, description, salary, schedule) => {
        setCurrentImage(imgSrc);
        setCurrentText(text);
        setDescription(description);
        setSalary(salary);
        setSchedule(schedule);
        setShow(true);
    };

    return (
        <section style={{ width: '90%', marginLeft: '5%' }} >
            <h6 style={{ fontSize: '30px' }} className="font-serif text-dark text-center font-medium mb-[25px] lg:mb-[40px]">Career Opportunities</h6>
            <Swiper
                effect={'panorama'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={3}
                loop={true}
                thumbs={{ Pagination, EffectPanorama }}
                modules={[EffectPanorama, Pagination]}
                // pagination={true}
                className="mySwiper"
                panoramaEffect={{
                    depth: 30,
                    rotate: 6,
                }}
                breakpoints={{
                    480: {
                        slidesPerView: 2,
                        panoramaEffect: {
                            rotate: 35,
                            depth: 150,
                        },
                    },
                    640: {
                        slidesPerView: 2,
                        panoramaEffect: {
                            rotate: 30,
                            depth: 150,
                        },
                    },
                    1024: {
                        slidesPerView: 4,
                        panoramaEffect: {
                            rotate: 30,
                            depth: 200,
                        },
                    },
                    1200: {
                        slidesPerView: 4,
                        panoramaEffect: {
                            rotate: 25,
                            depth: 250,
                        },
                    },
                }}
            >
                {data.map((slide) => (

                    <SwiperSlide className='w-full bg-[#ffffff] mx-auto '>
                        <h6 className='text-center lg:text-sm md:text-sm  sm:hidden'>{slide.title}</h6>
                        <img class="my-swiper" key={slide.id} src={slide.img} alt="" style={{ borderRadius: '5px', marginBottom: "20rem" }} onClick={() => handleShow(slide.img, slide.title, slide.description, slide.salary, slide.schedule)} />


                    </SwiperSlide>
                ))}
            </Swiper>
            <Modal className='w-full bg-[#ffffff] mx-[auto] ' fullscreen={true} show={show} onHide={handleClose}>
                <div >
                    <div className='top-container'>
                        <Modal.Body className='flex flex-wrap w-full justify-center text-9xl' closeButton>

                            <Modal.Title className='font-bold '>{currentText}</Modal.Title>

                        </Modal.Body>
                        <div style={{ position: 'absolute', float: 'right', top: 15, right: 34, fontSize: 26 }}>
                            <button variant="secondary" onClick={handleClose}>
                                X
                            </button>
                        </div>
                    </div>


                    <div className='justify-center items-center md:flex-col mx-[auto]'>

                        <div className=''>

                            <div className='flex sm:flex-col md:flex-row'>

                                <Modal.Body className='self-center p-2' >
                                    
                                    <img className='flex image-cover shadow-md shadow-[#040c16] rounded-md mx-[]  ' src={currentImage} alt={currentText} />
                                   

                                </Modal.Body>

                                <Modal.Body className='flex flex-wrap items-center '>

                                    <div className='w-[60rem]'>

                                        <h6 className='font-bold mb-[]'>Description</h6>

                                        <p className='mb-[1rem]'>{description}</p>

                                        <h6 className='font-bold mb-[]'>Salary</h6>

                                        <p className='mb-[1rem]'>{salary}</p>

                                        <h6 className='font-bold mb-[]'>Schedule</h6>

                                        <p className='mb-[1rem]'>{schedule}</p>

                                    </div>

                                </Modal.Body>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

        </section>
    )
}

export default SwiperCarousel;