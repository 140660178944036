import React from 'react';
import background from "../Assets/MSImages/107209-ON6SSY-427.jpg"

// Libraries
import { Col, Container, Row } from 'react-bootstrap'

// Component
import { fadeIn } from '../Functions/GlobalAnimations'

import IconWithText from "./Elements/IconWithText"



//Data
import { IconWithTextData_04 } from './Elements/IconWithTextData'


const IconWithTextPage = () => {
  return (
    <>
      {/* Section Start */}
      <section style={{paddingTop:'10rem'}} className="pt-[70px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <Container>
          <Row>
            <Col className="mb-[4%]">
              <h6 className="font-serif text-dark text-center font-medium mb-[25px] lg:mb-[15px]">Why You Should Work With Us!</h6>
            </Col>
          </Row>
          <IconWithText
            grid="row-cols-1 row-cols-lg-3 row-cols-sm-2 text-center gap-y-10"
            theme="icon-with-text-04"
            data={IconWithTextData_04}
            animation={fadeIn}
            animationDelay={0.2}
          />
        </Container>
      </section>
    </>
  )
}

export default IconWithTextPage