import React, { Suspense, useEffect, useState, lazy } from "react";
import breakpoints from "..//src/Assets/scss/breakpoints.scss";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import MilitarySolution from "./Pages/MilitarySolution";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";
import Employer from "./Pages/Employer";

// Home

function App() {
  return (
    <GlobalContext.Provider>
      <div className="App">
        {
          <main>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense>
                <Routes>
                  <Route path="/" element={<MilitarySolution />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/employer" element={<Employer />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
