import React from "react";
import CenterLogoNav from "./Elements/CenterLogoNav";
import LeafLet from "./LeafLet";
import { SwiperData } from "./Elements/CarouselData";
import HeroImage from "./Elements/HeroImage";
import TabPage from "./Elements/Tab";
import SwiperCarousel from "./Elements/SwiperCarousel";
import Footer from "../Components/Footer";
import { HeroData } from "./Elements/HeroImageData";

import Carousel from "./Elements/Carousel";

const MilitarySolution = () => {
  return (
    <div>
      <CenterLogoNav />
      <HeroImage data={HeroData} />
      <TabPage />
      <SwiperCarousel data={SwiperData} />
      {/* <Carousel data={SwiperData}/>
      <ImageGallery data={SwiperData} /> */}
      <LeafLet />
      {/* <LeafLet /> */}
      <Footer />
    </div>
  );
};

export default MilitarySolution;
