import React from "react";
import { FooterLink } from "./FooterStyles";
// import { Divider } from "rsuite";
// import "../../node_modules/rsuite/dist/rsuite.min.css";
import Divider from "@mui/material/Divider";

const Footer = () => {
  return (
    <div>
      <div className="flex justify-center text-center mt-[12.5rem] md:mt-[10rem] sm:mt-[8rem]">
        <div className="flex sm:flex-col mt-[2rem] xxs:flex-col">
          <FooterLink className="mx-[5px]" href="/privacypolicy">
            Privacy Policy
          </FooterLink>
          <Divider orientation="vertical" flexItem />
          <FooterLink className="mx-[5px]">936-391-8443</FooterLink>
          <Divider orientation="vertical" flexItem />
          <FooterLink className="mx-[5px]" href="#">
            mhardy@militarysolution.net
          </FooterLink>
          <Divider orientation="vertical" flexItem />
          <FooterLink className="mx-[5px]" href="#">
            © 2023 by Military Solution, LLC
          </FooterLink>
        </div>
      </div>
    </div>
  );
};
export default Footer;
